import {
  CNavbar,
  CContainer,
  CNavbarBrand,
  CNavbarToggler,
  CCollapse,
  CNavbarNav,
  CButton,
} from "@coreui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

export default function NavBar() {
  const navigate = useNavigate(); // Para la navegación
  const location = useLocation(); // Para obtener la URL actual
  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0); // "Beneficios" será el valor inicial (índice 0)
  const [open, setOpen] = useState("");

  const sections = [
    "Beneficios",
    "Actividades",
    "Hospedajes",
    "Contactarse",
    "Chats",
  ];

  useEffect(() => {
    // Actualizar el índice activo basado en la ruta actual
    if (location.pathname.includes("Actividades")) {
      setActiveIndex(1);
    } else if (location.pathname.includes("Hospedajes")) {
      setActiveIndex(2);
    } else if (location.pathname.includes("Contactarse")) {
      setActiveIndex(3);
    } else if (location.pathname.includes("Chats")) {
      setActiveIndex(4);
    } else {
      setActiveIndex(0); // "Beneficios" por defecto
    }
  }, [location.pathname]); // Se ejecuta cuando cambia la ruta

  const handleSectionClick = (index, path) => {
    setActiveIndex(index); // Actualiza el índice de la sección activa
    navigate(path); // Redirecciona a la ruta correspondiente
  };

  const handleHome = () => {
    navigate(`/Beneficios`);
  };

  const logOut = () => {
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.clear();
    navigate("/");
  };

  return (
    <div className="bg-grey">
      <CNavbar expand="lg">
        <CContainer fluid>
          <CNavbarBrand
            className="text-white text-3xl"
            onClick={() => handleHome(navigate)}
            style={{ cursor: "pointer" }}
          >
            StudentHub
          </CNavbarBrand>
          <CNavbarToggler
            aria-label="Toggle navigation"
            aria-expanded={visible}
            onClick={() => setVisible(!visible)}
          />
          <CCollapse className="navbar-collapse" visible={visible}>
            <CNavbarNav as="nav" className="mx-auto transform -translate-x-16">
              {sections.map((section, index) => (
                <button
                  key={index}
                  className={`text-white mx-3 text-xl ${
                    activeIndex === index ? "underline underline-offset-4" : ""
                  }`} // Subrayado solo en la sección activa
                  onClick={() => handleSectionClick(index, `/${section}`)} // Navegar a la página de la sección
                >
                  {section}
                </button>
              ))}
            </CNavbarNav>
          </CCollapse>
          <CButton
            className="me-md-2 text-white d-flex justify-content-center align-items-center bg-white profile-button"
            type="button"
            shape="rounded-pill"
            style={{
              width: "50px",
              height: "50px",
            }}
            onClick={() => setOpen((prev) => !prev)}
          >
            <span className="material-symbols-outlined text-grey text-[48px]">
              account_circle
            </span>{" "}
            {/* Para estilo outlined */}
          </CButton>
          {open && (
            <ul
              className="dropdownMenu"
              id="dropdownMenu"
              style={{
                position: "absolute",
                textAlign: "center",
                top: "63px",
                right: "10px",
                padding: "inherit",
                backgroundColor: "white",
                border: "0.2px solid rgb(106, 106, 106)",
                borderRadius: "6px",
                zIndex: 1000,
              }}
            >
              <li>
                <button
                  className="dropdownItem"
                  style={{ padding: "6px" }}
                  onClick={() => logOut()}
                >
                  Cerrar sesión
                </button>
              </li>
            </ul>
          )}
        </CContainer>
      </CNavbar>
    </div>
  );
}
