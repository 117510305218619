import "./LodgmentAdderButton.css";

const LodgmentAdderButton = ({ setOpen }) => {
  return (
    <div
      onClick={() => setOpen((prev) => !prev)}
      className="lodgment-adder-button-box "
    >
      <button className="lodgment-adder-button" />
    </div>
  );
};

export default LodgmentAdderButton;
