import React, { useEffect } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import "./quill.css";

const QuillPrompt = ({ quillRef }) => {
  useEffect(() => {
    if (quillRef.current === null) {
      quillRef.current = new Quill("#quill-editor", {
        modules: {
          toolbar: [
            [
              { font: [] },
              { header: [1, 2, 3, false] },
              "bold",
              "italic",
              "underline",
            ],
            [{ color: [] }, { background: [] }],
            [{ align: [] }, { list: "ordered" }, { list: "bullet" }],
            ["link", "image"],
          ],
        },
        placeholder: "Contenido del posteo",
        theme: "snow",
        formats: [
          "header",
          "list",
          "align",
          "bold",
          "italic",
          "underline",
          "background",
          "color",
          "font",
          "link",
          "image",
        ],
      });
    }
   // eslint-disable-next-line 
  }, []);

  return (
    <div className="quill-container">
      <div id="quill-editor" />
    </div>
  );
};

export default QuillPrompt;
