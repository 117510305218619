export default function BenefitCard({ imageUrl, title, onClick }) {
    return (
      <div className="relative w-[300px] max-w-sm h-[190px] rounded-lg overflow-hidden shadow-lg cursor-pointer"
            onClick={onClick}>
        <img
          src={imageUrl}
          alt="Promo background"
          className="absolute inset-0 w-full h-full object-cover"
        />
        <div
          className="absolute inset-0 z-10"
          style={{
            background: 'linear-gradient(to top, rgba(198, 192, 203, 0.90) 30%, transparent 70%)', // Ajuste aquí
          }}
        />
        <div className="absolute bottom-0 left-0 right-0 flex justify-center p-4 z-20">
          <h2 className="text-white text-lg font-bold text-center">{title}</h2>
        </div>
      </div>
    );
  }
  
  
  
  
  


