import React from "react";
import "./UsernamePrompt.css";

const UsernamePrompt = ({ onUsernameChange }) => {
  return (
    <div className="user-input">
      <input
        className="font-playWrite"
        id="user"
        placeholder="Nombre de usuario"
        onChange={(e) => onUsernameChange(e)}
      />
    </div>
  );
};

export default UsernamePrompt;
