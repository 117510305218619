import React from 'react';
import { Card, Carousel, Avatar, Typography, Popover, Button } from 'antd';
import { LeftOutlined, RightOutlined, UserOutlined, SettingOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const CardCarousel = ({ images = [], title, description, avatarUrl, onClick }) => {
  const popoverContent = (
    <div>
      <Button icon={<UserOutlined />} type="text" block style={{ marginBottom: '8px' }}>
        View Profile
      </Button>
      <Button icon={<SettingOutlined />} type="text" block>
        Settings
      </Button>
    </div>
  );

  const handleCardClick = (e) => {
    // Prevent click event from propagating if it originated from the avatar, carousel arrows, or dots
    if (
      !e.target.closest('.ant-avatar') &&
      !e.target.closest('.slick-arrow') &&
      !e.target.closest('.slick-dots')
    ) {
      onClick && onClick(e);
    }
  };

  // Custom arrow components
  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return <LeftOutlined className={className} onClick={onClick} />;
  };

  const NextArrow = (props) => {
    const { className, onClick } = props;
    return <RightOutlined className={className} onClick={onClick} />;
  };

  return (
    <Card
      style={{ 
        width: 400, 
        maxWidth: '100%',
        borderRadius: '16px 16px 8px 8px',
        overflow: 'hidden',
        transition: 'box-shadow 0.3s ease-in-out',
        cursor: 'pointer',
      }}
      hoverable
      onClick={handleCardClick}
      cover={
        images.length > 0 ? (
          <Carousel
            arrows
            prevArrow={<PrevArrow />}
            nextArrow={<NextArrow />}
            style={{ borderRadius: '16px 16px 0 0' }}
          >
            {images.map((image, index) => (
              <div key={index}>
                <img
                  alt={`Slide ${index + 1}`}
                  src={image}
                  style={{ width: '100%', height: 250, objectFit: 'cover' }}
                />
              </div>
            ))}
          </Carousel>
        ) : (
          <div style={{ width: '100%', height: 250, backgroundColor: '#f0f0f0', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '16px 16px 0 0' }}>
            No Image Available
          </div>
        )
      }
    >
      <Card.Meta
        avatar={
          avatarUrl ? (
            <Popover 
              content={popoverContent} 
              placement="leftBottom" 
              trigger="hover"
            >
              <Avatar src={avatarUrl} className="ant-avatar" />
            </Popover>
          ) : null
        }
        title={title && <Title level={4} style={{ marginBottom: 0 }}>{title}</Title>}
        description={description && <Paragraph ellipsis={{ rows: 2 }}>{description}</Paragraph>}
      />
    </Card>
  );
};

export default CardCarousel;