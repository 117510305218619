import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import handleAutoLogin from '../behaviour/handleAutoLogin';
import { Spin } from "antd";

const styles = {
    loadingContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh', // Ocupa toda la altura de la pantalla
      width: '100vw',  // Ocupa todo el ancho de la pantalla
    },
  };

const ProtectedRoute = ({ element }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // `null` mientras esperamos el resultado
  const [loading, setLoading] = useState(true); // Para gestionar el estado de carga

  // Función que maneja la autenticación
  const auth = async () => {
    const isLogged = await handleAutoLogin();
    setIsAuthenticated(isLogged);
    setLoading(false); // Deja de estar en estado de carga
  };

  // Llama a `auth` cuando el componente se monte
  useEffect(() => {
    auth();
  }, []);

  // Mientras cargamos la verificación de autenticación
  if (loading) {
    return (
        <div style={styles.loadingContainer}>
          <div><Spin size="large"/></div>
        </div>
      ); // Puedes poner cualquier componente de carga
  }

  // Si ya sabemos si está autenticado o no
  return isAuthenticated ? element : <Navigate to="/Login" />;
};

export default ProtectedRoute;

