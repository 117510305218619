import React from "react";
import "./Login.css";
import LoginBox from "../../auth/login/LoginBox";
import OuterNavBar from "../../OuterNavBar";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate(); // Para la navegación

  const handleRegister = () => {
    navigate("/Register");
  };

  return (
    <div className="Login-page">
      <OuterNavBar
        rightUpperButton={"Register"}
        rightUpperButtonFunction={handleRegister}
      />
      <LoginBox />
    </div>
  );
};

export default Login;
