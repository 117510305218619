import { useEffect, useState } from "react";
import { supabase } from '../../../supabaseClient';
import NavBar from "../../NavBar";
import { useParams } from "react-router-dom"; // Para obtener el ID de la actividad desde la URL
import DetailCard from "../../DetailCard";

export default function AcademicDetail() {
  const { id } = useParams(); // Obtenemos el id de la actividad desde la URL
  const [activity, setActivity] = useState(null); // Estado para la actividad

  useEffect(() => {
    const fetchActivityDetail = async () => {
      const { data, error } = await supabase
        .from('activity_academic') // Asegúrate de que el nombre de la tabla esté correcto
        .select(`name, description, image: id_image (base64image)`) // Selecciona los campos necesarios
        .eq('id', id) // Filtra por id de la actividad
        .single(); // Solo esperamos un resultado

      if (error) {
        console.error("Error fetching data: ", error);
      } else {
        // Guarda los detalles de la actividad en el estado
        setActivity({
          name: data.name,
          description: data.description,
          id_image: data.image.base64image,
        });
      }
    };

    fetchActivityDetail();
  }, [id]); // Ejecutar el efecto cuando cambie el id

  if (!activity) {
    return <div>Loading...</div>; // Mientras cargan los detalles
  }

  return (
    <div>
      {/* NavBar */}
      <NavBar sections={["Beneficios", "Actividades", "Hospedajes", "Conectarse", "Chats"]} />
      
      {/* Details */}
      <DetailCard title={activity.name} description={activity.description} image={activity.id_image} bgColor="bg-gray-purple"/>
    </div>
  );
}
