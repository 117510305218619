export default function ActivityTypeButton({ activityType, icon, onClick }) {
    return (
      <button
        className="w-[350px] h-[230px] bg-nardo-gray rounded-lg shadow-lg flex flex-col items-center justify-center space-y-4"
        onClick={onClick} // Llama a la función que viene del padre
      >
        <img src={icon} alt={`${activityType} icon`} className="w-[100px] h-[100px] object-contain" />
        <span className="text-4xl text-white">
          {activityType}
        </span>
      </button>
    );
  }