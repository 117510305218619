export default function CategoryButton({ categoryName, icon, isActive, onClick }) {
  return (
    <button
      className="w-[165px] h-[228px] bg-grey rounded-lg shadow-lg flex flex-col items-center justify-center space-y-4"
      onClick={onClick} // Llama a la función que viene del padre
    >
      <img src={icon} alt={`${categoryName} icon`} className="w-12 h-12 object-contain" />
      <span className={`text-2xl text-white ${isActive ? 'underline underline-offset-4' : ''}`}>
        {categoryName}
      </span>
    </button>
  );
}
