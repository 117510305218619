import React, { useRef } from "react";
import "./Support.css";
import emailjs from "@emailjs/browser";
import OuterNavBar from "../../OuterNavBar";
import { useNavigate } from "react-router-dom";
import handleAutoLogin from "../../behaviour/handleAutoLogin";
import handleHome from "../../behaviour/handleHome";

const checkAndAlert = (str, placeholderText) => {
  const data = document.getElementById(str);
  if (data.value.length === 0) {
    document
      .getElementById(str)
      .setAttribute("placeholder", `Por favor completa el campo`);
    setTimeout(() => {
      data.setAttribute("placeholder", placeholderText);
    }, 2500);
    return true;
  }
  return false;
};

const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    if (checkAndAlert("formName", "Name")) return;
    if (checkAndAlert("formMail", "Email")) return;
    if (checkAndAlert("formMessage", "Message")) return;

    emailjs
      .sendForm("service_coabltb", "template_g3cib5c", form.current, {
        publicKey: "hcU8QwBusP_XhiPHI",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          document.getElementById("formSend").setAttribute("value", "Sent!");
          document
            .getElementById("formSend")
            .setAttribute("disabled", "disabled");
          document.getElementById("formSend").style.cssText =
            "background-color: #cccccc";
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <form ref={form} onSubmit={sendEmail} id="form">
      <input type="text" name="name" id="formName" placeholder="Nombre" />
      <input type="email" name="mail" id="formMail" placeholder="Mail" />
      <input
        type="text"
        name="message"
        id="formMessage"
        placeholder="Mensaje"
      />
      <input type="submit" value="Enviar" id="formSend" />
    </form>
  );
};

const Support = () => {
  const navigate = useNavigate(); // Para la navegación

  const handleLogin = async () => {
    if (handleAutoLogin()) {
      handleHome(navigate);
    }
    navigate("/Login");
  };

  return (
    <div className="Support-page">
      {/* Incluye la barra de navegación */}
      <OuterNavBar
        rightUpperButton={"Login"}
        rightUpperButtonFunction={handleLogin}
      />

      <section className="Support">
        <div className="contactUs">
          <h1 id="contactTitle">Contactanos</h1>
          <h4 id="contactDescription">
            Puedes contactarnos para lo que desees en cualquier momento.
            Trataremos de responder lo mas pronto posible!
          </h4>
          <ContactUs />
        </div>
        <div className="info">
          <h1 id="infoTitle">Info</h1>
          <ul id="dataList">
            <li id="mail">info@studenthub.com</li>
            <li id="phone">+54 9 11 2162-6126</li>
            <li id="location">San Martín 1200</li>
            <li id="workHours">08:00 - 16:00</li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default Support;
