import React from "react";
import "./Verification.css";
import VerificationBox from "../../auth/verification/VerificationBox";
import OuterNavBar from "../../OuterNavBar";
import { useNavigate } from "react-router-dom";

import handleAutoLogin from "../../behaviour/handleAutoLogin";
import handleHome from "../../behaviour/handleHome";

const Verification = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    if (handleAutoLogin()) {
      handleHome(navigate);
    }
    navigate("/Login");
  };

  return (
    <div className="Verification-page">
      <OuterNavBar
        rightUpperButton={"Login"}
        rightUpperButtonFunction={handleLogin}
      />
      <VerificationBox />
    </div>
  );
};

export default Verification;
