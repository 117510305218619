export default function DetailCard({ title, description, image, bgColor}) {
    return (
        <div className="bg-gray-100 overflow-y-hidden">
            <div className={`rounded-3xl p-8 shadow-lg text-center text-white max-w-[1000px] w-full mx-auto mt-8 ${bgColor}`}>
                {/* Título */}
                <h1 className="text-4xl font-bold mb-6">{title}</h1>
    
                {/* Imagen centrada y con bordes redondeados */}
                <img 
                    src={image} 
                    alt={title} 
                    className="w-full h-[300px] object-cover rounded-2xl mx-auto mb-6" 
                />
    
                {/* Descripción */}
                <p className="text-2xl break-words">{description}</p>
            </div>
        </div>
    );    
}

  