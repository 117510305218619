import { CContainer, CForm, CFormInput } from '@coreui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

function SearchBar({
  query,
  matchingBenefits,
  errorMessage,
  handleInputChange,
  handleSearchSubmitWrapper,
  handleSuggestionClick,
}) {
  return (
    <div className="d-flex justify-content-center">
    <CContainer fluid className="pt-3 pb-3" style={{ width: "1100px", position: 'relative' }}>
        <CForm className="d-flex" onSubmit={handleSearchSubmitWrapper}>
          <CFormInput
            type="search"
            className="me-2"
            placeholder="Buscar"
            value={query}
            onChange={handleInputChange}
          />
          <button
            type="submit"
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
          >
          <FontAwesomeIcon className="bg-grey text-white text-[25px] rounded-lg p-2" icon={faMagnifyingGlass} />
          </button>
        </CForm>

        {matchingBenefits && matchingBenefits.length > 0 && (
        <div className="suggestions" style={{ backgroundColor: '#ffffff', borderRadius: '5px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)' }}>
            {matchingBenefits.map((benefit, index) => (
              <div
                key={index}
                className="suggestion-item d-flex align-items-center"
                onClick={() => handleSuggestionClick(benefit.name)}
                style={{ cursor: 'pointer', padding: '5px', borderBottom: '1px solid #ddd' }}
              >
                {benefit.images && benefit.images.length > 0 && (
                  <img
                    src={benefit.images[0]}
                    alt={benefit.name}
                    style={{ width: '40px', height: '40px', marginRight: '10px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                )}
                <span>{benefit.name}</span>
              </div>
            ))}
          </div>
        )}

        {errorMessage && <p>{errorMessage}</p>}
      </CContainer>
    </div>
  );
}

export default SearchBar;
