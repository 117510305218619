import React, { useState, useEffect } from "react";
import CardCarousel from "../../carrouselCard";
import AddressAutocompleteMixed from "../../geo/Geoconvertermixed";
import LodgmentAdderButton from "../../lodgment/lodgmentAdderButton/LodgmentAdderButton";
import LodgmentAdderCanvas from "../../lodgment/lodgmentAdderCanvas/LodgmentAdderCanvas";
import { Pagination } from "antd";
import NavBar from "../../NavBar";

//const { Title, Paragraph } = Typography;

const CardPage = () => {
  const rareUniversityId = localStorage.getItem("selectedUniversityId");
  const universityId = JSON.parse(rareUniversityId);
  // eslint-disable-next-line
  const [defaultLocation, setDefaultLocation] = useState(null);
  const [chosenLocation, setChosenLocation] = useState([]);
  const [posts, setPosts] = useState([]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchCoordinates = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/universities/coordinates/${universityId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Error fetching coordinates");
        }

        const coordinates = await response.json();
        setDefaultLocation(coordinates);
        const location = {
          lat: coordinates.latitud,
          lon: coordinates.longitud,
        };
        setChosenLocation(location);
      } catch (error) {
        console.error("Error fetching coordinates:", error);
      }
    };
    fetchCoordinates();
  }, [universityId]);

  useEffect(() => {
    const fetchPosts = async () => {
      if (chosenLocation.lat != null && chosenLocation.lon != null) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/lodging/lodging_post/${chosenLocation.lat}/${chosenLocation.lon}/10`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (!response.ok) {
            if (response.status === 404) {
              setPosts([]);
            }
            throw new Error("Error fetching posts");
          }

          const posts = await response.json();

          const cardsArray = posts.map((post, index) => (
            <CardCarousel
              key={index}
              images={post.images}
              title={post.name}
              description={post.brief_description}
              avatarUrl={post.profile_picture}
              onClick={() => console.log(post.profile_picture)}
            />
          ));

          setPosts(cardsArray);
        } catch (error) {
          console.error("Error fetching posts:", error);
        }
      }
    };
    fetchPosts();
  }, [chosenLocation]);

  // Estado para manejar la paginación
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 8;

  // Obtener las tarjetas correspondientes a la página actual
  const paginatedCards = posts.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  // Manejar el cambio de página
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="relative">
      {/* NavBar */}
      <div>
        <NavBar
          sections={[
            "Beneficios",
            "Actividades",
            "Hospedajes",
            "Conectarse",
            "Chats",
          ]}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "20px",
          marginBottom: "40px",
          marginTop: "40px",
        }}
      >
        <AddressAutocompleteMixed setChosenLocation={setChosenLocation} />
      </div>

      {/* Galería de tarjetas paginadas */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        {paginatedCards}
      </div>

      {/* Paginación con botones comprimidos */}
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "40px" }}
      >
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={posts.length}
          onChange={handlePageChange}
          showSizeChanger={false} // Ocultar el selector de tamaño de página
          showLessItems // Mostrar la versión comprimida de la paginación
        />
      </div>

      <LodgmentAdderButton setOpen={setOpen} />
      {open && <LodgmentAdderCanvas open={open} setOpen={setOpen} />}
    </div>
  );
};

export default CardPage;
