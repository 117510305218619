import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";

const AddressAutocompleteMixed = ({ setChosenLocation }) => {
  const [address, setAddress] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          });
        },
        (err) => {
          setError("Error obteniendo la ubicación.");
        }
      );
    }
  }, []);

  const calculateDistance = useCallback((lat1, lon1, lat2, lon2) => {
    const R = 6371;
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  }, []);

  const debounce = useCallback((func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }, []);

  const getSuggestions = useCallback(
    async (query) => {
      if (query.length < 5) {
        setSuggestions([]);
        return;
      }

      try {
        const url = `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
          query
        )}&addressdetails=1&limit=6`;
        const response = await axios.get(url);
        let data = response.data;

        let sortedByDistance = [];
        let remainingSuggestions = [];

        if (location) {
          sortedByDistance = data
            .filter((item) => item.lat && item.lon)
            .map((item) => ({
              ...item,
              distance: calculateDistance(
                location.lat,
                location.lon,
                parseFloat(item.lat),
                parseFloat(item.lon)
              ),
            }))
            .sort((a, b) => a.distance - b.distance)
            .slice(0, 3);
        }

        if (sortedByDistance.length < 3) {
          remainingSuggestions = data.filter(
            (item) =>
              !sortedByDistance.some((s) => s.place_id === item.place_id)
          );
          sortedByDistance = [
            ...sortedByDistance,
            ...remainingSuggestions.slice(0, 3 - sortedByDistance.length),
          ];
        }

        setSuggestions(sortedByDistance);
      } catch (err) {
        setError("Error obteniendo sugerencias.");
      }
    },
    [location, calculateDistance]
  );

  const handleSelect = useCallback(
    (suggestion) => {
      setAddress(suggestion.display_name);
      const location = {
        lat: suggestion.lat,
        lon: suggestion.lon,
      };
      setChosenLocation(location); // Actualizar el estado en el componente padre
      setSuggestions([]);
    },
    [setChosenLocation]
  );

  // eslint-disable-next-line
  const debouncedGetSuggestions = useCallback(
    debounce((query) => getSuggestions(query), 500),
    [getSuggestions, debounce]
  );

  return (
    <div style={{ position: "relative" }}>
      <input
        type="text"
        value={address}
        onChange={(e) => {
          setAddress(e.target.value);
          debouncedGetSuggestions(e.target.value);
        }}
        style={{
          width: "100%",
          padding: "6px",
          border: "1.75px solid black",
          borderRadius: "6px",
        }}
        placeholder="Ingresa una dirección"
      />
      {suggestions.length > 0 && (
        <ul
          style={{
            border: "1.6px solid black",
            listStyle: "none",
            padding: "0",
            marginTop: "5px",
            position: "absolute",
            backgroundColor: "white",
            zIndex: "1001",
            borderRadius: "9px",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          {suggestions.map((suggestion) => (
            <li
              key={`${suggestion.place_id}-${suggestion.osm_id}`}
              onClick={() => handleSelect(suggestion)}
              style={{ padding: "10px", cursor: "pointer", width: "auto" }}
            >
              {suggestion.display_name}
            </li>
          ))}
        </ul>
      )}
      {error && <p>{error}</p>}
    </div>
  );
};

export default AddressAutocompleteMixed;
