import React, { useState } from "react";
import "./VerifiedBox.css";
import { useEffect } from "react";

function VerifiedBox({ passedToken }) {
  const token = passedToken;
  const [message, setMessage] = useState("");

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/users/validate_email/${token}`
        );
        const data = await response.json();

        if (response.ok) {
          setMessage("Correo verificado con éxito");
        } else {
          setMessage("Error al verificar el correo: " + data.message);
        }
      } catch (error) {
        console.error("Error en la verificación del correo:", error);
        setMessage(
          "Hubo un error al verificar el correo! Por favor intenta de nuevo."
        );
      }
    };

    if (token) {
      verifyEmail();
    }
  }, [token]);

  return (
    <section className="verification-box">
      <h1 className="font-playWrite" id="title">
        Verificación de correo
      </h1>
      <p>{message}</p>
    </section>
  );
}

export default VerifiedBox;
