import React from "react";
import "./PasswordPrompt.css";

const PasswordPrompt = ({ onPasswordChange }) => {
  const togglePass = (elementId, passButtonId) => {
    const elem = document.getElementById(elementId);
    const button = document.getElementById(passButtonId);
    if (elem.type === "password") {
      elem.type = "text";
      button.className = "showPassButtonOff";
    } else {
      elem.type = "password";
      button.className = "showPassButtonOn";
    }
  };

  return (
    <div className="passPrompt">
      <input
        className="font-playWrite password-input"
        id="password"
        type="password"
        placeholder="Contraseña"
        onChange={(e) => onPasswordChange(e)}
      />
      <button
        id="passButton"
        className="showPassButtonOn"
        onClick={() => togglePass("password", "passButton")}
      />
    </div>
  );
};

export default PasswordPrompt;
