export default function ActivityCard({ imageUrl, title, onClick }) {
    return (
      <div className="relative w-[400px] max-w-sm h-[250px] rounded-lg overflow-hidden shadow-lg cursor-pointer"
            onClick={onClick}>
        <img
          src={imageUrl}
          alt="Promo background"
          className="absolute inset-0 w-full h-full object-cover"
        />
        <div
          className="absolute inset-0 z-10"
          style={{
            background: 'linear-gradient(to top, rgba(120, 111, 128, 0.95) 30%, transparent 70%)', // Ajuste aquí
          }}
        />
        <div className="absolute bottom-0 left-0 right-0 flex justify-center p-4 z-20">
          <h2 className="text-pearl text-2xl font-bold text-center">{title}</h2>
        </div>
      </div>
    );
}
