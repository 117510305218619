import React from "react";
import "./Verified.css";
import { useParams } from "react-router-dom"; // Importar useLocation
import OuterNavBar from "../../OuterNavBar"; // Importa la barra de navegación
import { useNavigate } from "react-router-dom";
import VerifiedBox from "../../auth/verified/VerifiedBox";
import handleHome from "../../behaviour/handleHome";
import handleAutoLogin from "../../behaviour/handleAutoLogin";

const Verified = () => {
  const { token } = useParams();

  const navigate = useNavigate();

  const handleLogin = () => {
    if (handleAutoLogin()) {
      handleHome(navigate);
    }
    navigate("/Login");
  };

  return (
    <div className="Verified-page">
      <OuterNavBar
        rightUpperButton={"Login"}
        rightUpperButtonFunction={handleLogin}
      />
      <VerifiedBox passedToken={token} />
    </div>
  );
};

export default Verified;
