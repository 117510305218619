import React from "react";
import "./VerificationBox.css";

const VerificationBox = () => {
  return (
    <section className="verification-box">
      <h1 className="font-playWrite" id="title">
        Pendiente de verificación
      </h1>
      <div>
        Su cuenta ha sido creada exitosamente, por favor revise su mail para
        terminar de verificarla.
      </div>
    </section>
  );
};

export default VerificationBox;
