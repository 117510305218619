import { CRow, CCol, CCardImage, CCardBody, CCard, CCardTitle, CCardText } from '@coreui/react';

export default function SectionCard ({ title, description, img }) {
    return (
        <CCol md={6}>
            <CCard className="mb-3">
              <CRow className="g-0">
                <CCol md={4}>
                  <CCardImage src={img} />
                </CCol>
                <CCol md={8}>
                  <CCardBody>
                    <CCardTitle className="font-roboto font-bold text-xl"> {title} </CCardTitle>
                    <CCardText className='font-roboto text-lg'> {description} </CCardText>
                  </CCardBody>
                </CCol>
              </CRow>
            </CCard>
          </CCol>
    );
}