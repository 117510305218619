import { useRef, useState } from "react";
import ImagesUpload from "../imagesUpload/ImagesUpload";

import "./LodgmentAdderCanvas.css";
import AddressAutocompleteMixed from "../../geo/Geoconvertermixed";
import QuillPrompt from "../quill/QuillPrompt";
import { getCookie } from "../../behaviour/getCookies";

const charCounter = (inputField, pId) => {
  const maxLength = inputField.getAttribute("maxlength");
  const currentLength = inputField.value.length;
  const remChars = document.getElementById(pId);

  if (currentLength === 0) {
    remChars.innerHTML = "";
  } else {
    remChars.innerHTML = `${maxLength - currentLength} characters left`;
  }
};

const LodgmentAdderCanvas = ({ open, setOpen }) => {
  const [title, setTitle] = useState("");
  const [briefDescription, setBriefDescription] = useState("");
  const [fileList, setFileList] = useState([]);
  const [chosenLocation, setChosenLocation] = useState(null);
  const quillRef = useRef(null);

  const storeLodge = async (content) => {
    console.log("content " + content);

    if (!chosenLocation) {
      console.log(chosenLocation);
      return;
    }
    const latitude = chosenLocation.lat;
    const longitude = chosenLocation.lon;

    const token = getCookie("token");

    if (token === null) return false;

    const body = {
      title,
      briefDescription,
      value: content,
      latitude,
      longitude,
      fileList,
      token,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/lodging/store_lodge`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      const data = await response.json();
      if (response.ok) {
        console.log("Usuario registrado con éxito:", data);
      } else {
        console.error("Error al registrar usuario:", data.message);
      }
    } catch (e) {
      console.error("Error en la solicitud:", e);
    }
  };

  return (
    <div className="lodgment-adder-canvas">
      <div className="lodgment-adder-box">
        <h3 className="h3">Agrega tu hospedaje</h3>
        <div className="lab-title-box">
          <input
            className="lab-title"
            type="text"
            placeholder="Title"
            maxLength="100"
            value={title}
            onInput={(e) => {
              charCounter(e.target, "lab-title-remaining-chars");
              setTitle(e.target.value);
            }}
          />
          <p id="lab-title-remaining-chars" />
        </div>
        <AddressAutocompleteMixed setChosenLocation={setChosenLocation} />
        <div className="lab-description-box">
          <div className="lab-brief-description">
            <textarea
              className="lab-description"
              maxLength="300"
              placeholder="Description"
              value={briefDescription}
              onInput={(e) => {
                charCounter(e.target, "lab-description-remaining-chars");
                setBriefDescription(e.target.value);
              }}
            />
            <p id="lab-description-remaining-chars" />
          </div>
          <QuillPrompt quillRef={quillRef} />
        </div>
        <div className="lab-upload">
          <p>Imagenes: {fileList.length}/8</p>
          <ImagesUpload
            id="upload-component"
            fileList={fileList}
            setFileList={setFileList}
          />
        </div>
        <div className="lab-buttons">
          <button onClick={() => setOpen((prev) => !prev)} id="lab-cancel">
            Cancelar
          </button>
          <button
            onClick={() => {
              storeLodge(quillRef.current.getContents());
              setOpen((prev) => !prev);
            }}
            id="lab-publish"
          >
            Publicar
          </button>
        </div>
      </div>
    </div>
  );
};

export default LodgmentAdderCanvas;
