import React from "react";
import OuterNavBar from "../../OuterNavBar";
import {
  CRow
} from "@coreui/react";
import SectionCard from "../../SectionCard";
import { useNavigate } from "react-router-dom";
import handleHome from "../../behaviour/handleHome";
import handleAutoLogin from "../../behaviour/handleAutoLogin";

export default function LandingPage() {
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (handleAutoLogin()) {
      handleHome(navigate);
    }
    navigate("/Login");
  };

  return (
    <div className="relative h-screen">
      {/* Imagen de fondo que cubre solo la mitad superior */}
      <div
        className="absolute inset-x-0 top-0 h-1/2 bg-cover bg-center"
        style={{
          backgroundImage: `url('https://images.unsplash.com/photo-1543193158-07c01963e800?q=80&w=2065&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,
        }}
      >
        {/* Mantiene padding en la navbar */}
        <div className="relative z-10">
          <OuterNavBar
            rightUpperButton={"Login"}
            rightUpperButtonFunction={handleLogin}
          />
        </div>

        {/* Texto centrado sobre la imagen */}
        <div className="font-roboto absolute inset-0 flex flex-col items-center justify-center z-0">
          <h1 className="font-roboto text-5xl font-bold text-white">
            ¡Bienvenidos a StudentHub!
          </h1>
          <p className="font-roboto text-2xl text-white mt-8">
            La plataforma para conectar con estudiantes y conocer los beneficios
            que ofrece tu Universidad
          </p>
        </div>
      </div>

      {/* Cards en la mitad inferior */}
      <div className="absolute bottom-0 top-1/2 w-full p-4 z-10 font-roboto">
        <CRow className="g-4">
          {/* Card 1 */}
          <SectionCard
            title={"BENEFICIOS"}
            description={
              "Descubre todos los beneficios que ofrece tu Universidad"
            }
            img={
              "https://images.unsplash.com/photo-1515187029135-18ee286d815b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            }
          />

          {/* Card 2 */}
          <SectionCard
            title={"ACTIVIDADES"}
            description={
              "Investiga acerca de las actividades que puedes realizar como estudiante"
            }
            img={
              "https://images.unsplash.com/photo-1531669494349-268cb716a234?q=80&w=2073&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            }
          />

          {/* Card 3 */}
          <SectionCard
            title={"CONECTAR CON PERSONAS"}
            description={"Conéctate con otros estudiantes que estén cerca tuyo"}
            img={
              "https://images.unsplash.com/photo-1520881363902-a0ff4e722963?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            }
          />

          {/* Card 4 */}
          <SectionCard
            title={"ALOJAMIENTOS"}
            description={
              "Encuentra alojamiento o personas con quienes compartir gastos si eres estudiante de intercambio"
            }
            img={
              "https://plus.unsplash.com/premium_photo-1663054457595-99a25ebb4522?q=80&w=2060&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            }
          />
        </CRow>
      </div>
    </div>
  );
}
