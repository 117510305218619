import React from "react";
import PasswordPrompt from "./passwordPrompt/PasswordPrompt";
import ConfirmPasswordPrompt from "./confirmPasswordPrompt/ConfirmPasswordPrompt";

const PasswordRegister = ({ onPasswordChange, onPasswordConfirmChange }) => {
  return (
    <div className="password">
      <PasswordPrompt onPasswordChange={onPasswordChange} />
      <ConfirmPasswordPrompt
        onPasswordConfirmChange={onPasswordConfirmChange}
      />
    </div>
  );
};

export default PasswordRegister;
