// FloatingButton.js
import React from 'react';

function FloatingButton({
    showForm,
    handleButtonClick,
    handleFormClose,
    handleSubmit,
    handleImageUpload,
    handleCategoryChange,
    categoryOption,
    showNewCategoryForm,
    categoryError,
    handleCategoryInputChange,
    setName,
    setDescription,
    setNewCategoryName,
    setNewCategoryIcon,
    handleNewCategorySubmit
}) {
    return (
        <div>
            <button
                className="fixed bottom-4 right-5 bg-grey text-white font-bold rounded-2xl w-[60px] h-[60px] flex items-center justify-center text-4xl hover:bg-grey-20 z-10"
                onClick={handleButtonClick}
                style={{ boxShadow: '0 4px 10px rgba(0, 0, 0, 0.50)' }}  // Sombra personalizada
            >
                +
            </button>


            {showForm && (
                <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-20">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md relative">
                        <h2 className="text-2xl mb-4">Ingresar Beneficio</h2>
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div>
                                <label className="block text-lg font-medium mb-1">Imagen</label>
                                <input type="file" accept="image/*" required onChange={handleImageUpload} className="border rounded px-3 py-2 w-full" />
                            </div>
                            <div>
                                <label className="block text-lg font-medium mb-1">Nombre</label>
                                <input type="text" required onChange={e => setName(e.target.value)} className="border rounded px-3 py-2 w-full" />
                            </div>
                            <div>
                                <label className="block text-lg font-medium mb-1">Descripción</label>
                                <textarea required onChange={e => setDescription(e.target.value)} className="border rounded px-3 py-2 w-full"></textarea>
                            </div>
                            <div>
                                <label className="block text-lg font-medium mb-1">Categoría</label>
                                <div className="flex space-x-4 mb-2">
                                    <label className="flex items-center">
                                        <input
                                            type="radio"
                                            name="categoryOption"
                                            value="existing"
                                            checked={categoryOption === 'existing'}
                                            onChange={() => handleCategoryChange('existing')}
                                            className="mr-2"
                                        />
                                        Categoría existente
                                    </label>
                                    <label className="flex items-center">
                                        <input
                                            type="radio"
                                            name="categoryOption"
                                            value="new"
                                            checked={categoryOption === 'new'}
                                            onChange={() => handleCategoryChange('new')}
                                            className="mr-2"
                                        />
                                        Agregar nueva categoría
                                    </label>
                                </div>
                                {categoryOption === 'existing' && (
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Nombre de la categoría"
                                            required
                                            onChange={handleCategoryInputChange}
                                            className="border rounded px-3 py-2 w-full"
                                        />
                                        {categoryError && (
                                            <p className="text-red-500 text-sm mt-2">{categoryError}</p>
                                        )}
                                    </div>
                                )}
                            </div>
                            {showNewCategoryForm && (
                                <div className="space-y-4 border-t pt-4 mt-4">
                                    <h3 className="text-xl mb-2">Agregar nueva categoría</h3>
                                    <div>
                                        <label className="block text-lg font-medium mb-1">Nombre de la categoría</label>
                                        <input type="text" required onChange={e => setNewCategoryName(e.target.value)} className="border rounded px-3 py-2 w-full" />
                                    </div>
                                    <div>
                                        <label className="block text-lg font-medium mb-1">Ícono (JPG)</label>
                                        <input type="file" accept="image/jpeg" required onChange={e => setNewCategoryIcon(e.target.files[0])} className="border rounded px-3 py-2 w-full" />
                                    </div>
                                    <div className="flex justify-end space-x-2 mt-4">
                                        <button
                                            type="button"
                                            onClick={handleNewCategorySubmit}
                                            className="bg-grey hover:bg-blue-700 text-black py-2 px-4 rounded border border-black"
                                        >
                                            Insertar categoría
                                        </button>
                                    </div>
                                </div>
                            )}
                            <div className="flex justify-end space-x-2 mt-4">
                                <button
                                    type="button"
                                    onClick={handleFormClose}
                                    className="bg-grey hover:bg-gray-700 text-white py-2 px-4 rounded border"
                                >
                                    Cancelar
                                </button>
                                <button
                                    type="submit"
                                    className="bg-grey hover:bg-green-700 text-white py-2 px-4 rounded border "
                                >
                                    Agregar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}

export default FloatingButton;
