import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./RegEmailPrompt.css";

const RegEmailPrompt = ({ onDataChange, onDomainChange }) => {
  const location = useLocation(); // Hook para obtener el query parameter
  const queryParams = new URLSearchParams(location.search); // Crear un objeto para leer los query parameters
  const categoryId = queryParams.get("");

  const [open, setOpen] = useState("");
  const [currentMail, setCurrentMail] = useState("@example.edu.ar"); // Use state for currentMail
  const [categories, setCategories] = useState([]);

  const getInfoAndUpdate = (event) => {
    const selectedDomain = event.target.innerHTML;
    setCurrentMail(selectedDomain);
    onDomainChange(selectedDomain);
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/universities/domains`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Error fetching categories");
        }

        const categories = await response.json();

        setCategories(categories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [categoryId]);

  const getCategories = () => {
    return categories.map((category, index) => (
      <li key={index}>
        <button className="dropdownItem" onClick={getInfoAndUpdate}>
          {category}
        </button>
      </li>
    ));
  };

  return (
    <div className="RegMail">
      <input
        type="text"
        id="data"
        placeholder="Mail"
        onChange={(e) => onDataChange(e)}
      />
      <span id="value">
        {currentMail}
        {open && (
          <ul className="dropdownMenu" id="dropdownMenu">
            {getCategories()}
          </ul>
        )}
      </span>
      <button id="dropdownButton" onClick={() => setOpen((prev) => !prev)} />
    </div>
  );
};

export default RegEmailPrompt;
