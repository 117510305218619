import {
  CNavbar,
  CContainer,
  CNavbarBrand,
  CNavbarToggler,
  CCollapse,
  CNavbarNav,
  CButton,
} from "@coreui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

export default function OuterNavBar({
  rightUpperButton,
  rightUpperButtonFunction,
}) {
  const navigate = useNavigate(); // Para la navegación
  const location = useLocation(); // Para obtener la URL actual

  const [visible, setVisible] = useState(false);
  const [activeSection, setActiveSection] = useState(""); // Estado para la sección seleccionada

  // Actualizar la sección activa basada en la URL
  useEffect(() => {
    if (location.pathname === "/Contact") {
      setActiveSection("contact");
    } else if (location.pathname === "/History") {
      setActiveSection("history");
    } else {
      setActiveSection(""); // Limpia si es una ruta distinta
    }
  }, [location.pathname]); // Escuchar cambios en la ruta

  const handleLanding = () => {
    navigate("/"); // Redirecciona a la landing page
  };

  const handleContact = () => {
    navigate("/Contact"); // Redirige a "Contacto"
  };

  const handleHistory = () => {
    navigate("/History"); // Redirige a "Nuestra Historia"
  };

  return (
    <div className="bg-grey">
      <CNavbar expand="lg">
        <CContainer fluid>
          <CNavbarBrand
            className="text-white text-3xl"
            onClick={handleLanding}
            style={{ cursor: 'pointer' }}
          >
            StudentHub
          </CNavbarBrand>
          <CNavbarToggler
            aria-label="Toggle navigation"
            aria-expanded={visible}
            onClick={() => setVisible(!visible)}
          />
          <CCollapse className="navbar-collapse" visible={visible}>
            <CNavbarNav as="nav" className="mx-auto transform -translate-x-8">
              <button
                className={`mx-3 text-xl ${
                  activeSection === "contact" ? "underline text-white" : "text-white"
                }`}
                onClick={handleContact}
              >
                <div>Contacto</div>
              </button>

              <button
                className={`mx-3 text-xl ${
                  activeSection === "history" ? "underline text-white" : "text-white"
                }`}
                onClick={handleHistory}
              >
                <div>Nuestra Historia</div>
              </button>
            </CNavbarNav>
          </CCollapse>

          <CButton
            className="me-md-2 text-white d-flex justify-content-center align-items-center bg-white"
            type="button"
            shape="rounded-pill"
            onClick={rightUpperButtonFunction}
            style={{
              width: "100px",
              height: "50px",
            }}
          >
            <div className="text-grey text-2xl">{rightUpperButton}</div>
          </CButton>
        </CContainer>
      </CNavbar>
    </div>
  );
}
